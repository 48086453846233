import React from 'react';
import { ParagraphImageTextTeaserFragmentFragment } from '../../../generated/types';
import { Box } from '@chakra-ui/react';
import getIconForCategoryKey from '../../../utils/getIconForCategoryKey';
import { HTMLText } from '../../Common/HTMLText';
import ImageTextTeaserBase from '../../Common/ImageTextTeaserBase';

export interface ImageTextTeaserProps {
  data: ParagraphImageTextTeaserFragmentFragment;
}

const ImageTextTeaser: React.FC<ImageTextTeaserProps> = ({ data }) => {
  const Icon = data.category ? getIconForCategoryKey(data.category.key!) : null;

  const fallbackUrl = data.customLink || '/';
  const url = data.customLink ? fallbackUrl : data.category?.searchPageUrl;

  return (
    <ImageTextTeaserBase
      image={data.image.teaser.url}
      url={url}
      linkText={data.linkText}
    >
      {Icon && (
        <Box
          alignSelf="flex-start"
          padding={2}
          borderRadius={{
            base: 0,
            md: 'lg',
          }}
          display="inline-flex"
          fontSize="3xl"
          border="2px solid"
          borderColor="#fff"
          color="textColor"
        >
          <Icon />
        </Box>
      )}
      <Box
        marginTop={4}
        fontSize="2xl"
        textTransform="uppercase"
        fontWeight="bold"
      >
        {data.title && <Box>{data.title}</Box>}
        {data.subtitle && <Box>{data.subtitle}</Box>}
      </Box>
      <Box marginY={2} width="60px" height="2px" bg="brand.600" />
      <Box marginTop={4} fontSize="lg">
        <HTMLText text={data.body} truncate={120} />
      </Box>
    </ImageTextTeaserBase>
  );
};

export default ImageTextTeaser;
