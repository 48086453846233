import React, { ComponentType } from 'react';
import { Box, Button, Img, Flex, FlexProps, ImgProps } from '@chakra-ui/react';
import getIconForCategoryKey from '../../utils/getIconForCategoryKey';
import { HTMLText } from './HTMLText';
import Link from 'next/link';
import LazyLoad from 'react-lazyload';

export type ImageTextTeaserProps = FlexProps & {
  image: string;
  url?: string;
  linkText: string;
  noCta?: boolean;
  imgProps?: ImgProps;
};

const ImageTextTeaserBase: React.FC<ImageTextTeaserProps> = ({
  image,
  url,
  linkText,
  children,
  noCta,
  imgProps,
  ...props
}) => {
  return (
    <Box pos="relative">
      <LazyLoad>
        <Img
          width="100%"
          htmlWidth="400px"
          htmlHeight="440px"
          src={image}
          borderRadius={{
            base: 0,
            md: 'lg',
          }}
          {...imgProps}
        />
      </LazyLoad>
      <Flex
        flexDirection="column"
        pos="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        my={4}
        mx={{
          base: 0,
          md: 4,
        }}
        color="#fff"
        bg={`rgba(0,0,0, .5)`}
        borderRadius={{
          base: 0,
          md: 'lg',
        }}
        padding={4}
        {...props}
      >
        {children}
        {url && !noCta && (
          <Box marginTop="auto">
            <Link href={url}>
              <a>
                <Button width="100%" as="span">
                  {linkText}
                </Button>
              </a>
            </Link>
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default ImageTextTeaserBase;
